<template>
  <OrderQuery breadcrumbTitle="Completed" query="?status=Done" />
</template>

<script>
import OrderQuery from '@/components/order/OrderQuery.vue';

export default {
  name: 'OrderCompleted',
  components: {
    OrderQuery,
  },
};
</script>

<style></style>
